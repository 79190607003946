import React, { useState, useEffect } from 'react';
import Navi from '@components/navbar';
import Tetris from '@components/Tetris';
import clsx from 'clsx';

// css
import Button from '@components/button';

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEgg, faSquare } from '@fortawesome/sharp-regular-svg-icons';

const PrismaTetris = () => {
  const useWindowWidth = () => {
    const isBrowser = typeof window !== 'undefined';
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

    useEffect(() => {
      if (typeof window === 'undefined') return;

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });

    return width;
  };
  const windowWidth = useWindowWidth();

  if (windowWidth > 450) {
    return (
      <>
        <Navi blok={{}} />
        <div className={clsx('flex h-screen')}>
          <div
            className={clsx('flex-[3] bg-neutral-shade h-full flex items-center justify-center')}
          >
            <div>
              <div className={clsx('p-0 md:p-4')}>
                <h2>Prismetris</h2>
                <p>Congratulations on finding our easter egg!</p>
                <p> We hope you enjoy some old school fun.</p>
                <ul className={clsx('p-0')} style={{ listStyle: 'none' }}>
                  <li>
                    <b>Rotate:</b> up key
                  </li>
                  <li>
                    <b>Move:</b> left and right keys
                  </li>
                  <li>
                    <b>Hold piece:</b> C
                  </li>
                  <li>
                    <b>Hard drop:</b> space
                  </li>
                </ul>
                <div className={clsx('flex gap-4 mt-4')}>
                  <a onClick={() => window.location.reload(false)}>
                    <Button
                      blok={{
                        type: 'secondary',
                        link: { cached_url: '', linktype: 'story' },
                        text: 'Restart',
                        new_window: false,
                        outbound_icon: false,
                      }}
                      darkMode={false}
                    />
                  </a>
                  <Button
                    blok={{
                      type: 'primary',
                      link: { cached_url: 'overview/inclusive-design', linktype: 'story' },
                      text: 'Inclusive Design',
                      new_window: false,
                      outbound_icon: false,
                    }}
                    darkMode={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={clsx('flex-[5] flex items-center justify-center')}>
            <div className={windowWidth > 768 ? 'game-board' : 'game-board p-x-md'}>
              <Tetris />
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Navi blok={{}} />
      <div className={clsx('mt-20 p-4')}>
        <div className={'p-0 md:p-4'}>
          <h2>Prismetris</h2>
          <p>
            <FontAwesomeIcon icon={faEgg} className={clsx('text-accent')} /> Well done! you found
            the easter egg <FontAwesomeIcon icon={faEgg} className={clsx('text-accent')} />
          </p>
          <p>Now open the page on desktop so you can actually play the game! </p>
          <p>
            <span className={clsx('text-link-dark')}>
              <FontAwesomeIcon icon={faSquare} style={{ paddingRight: '1px' }} />
              <FontAwesomeIcon icon={faSquare} style={{ paddingRight: '1px' }} />
              <FontAwesomeIcon icon={faSquare} style={{ paddingRight: '1px' }} />
              <FontAwesomeIcon icon={faSquare} style={{ paddingRight: '1px' }} />
            </span>
          </p>
          <div className="button-container">
            <Button
              blok={{
                type: 'primary',
                link: { cached_url: 'overview/inclusive-design', linktype: 'story' },
                text: 'Inclusive Design',
                new_window: false,
                outbound_icon: false,
              }}
              darkMode={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrismaTetris;
