import React, { useEffect, useState } from 'react';
import Link from 'gatsby-link';
import { globalHistory } from '@reach/router';
import clsx from 'clsx';

// context
import { usePageCtx } from '@layout';

// css
import {
  primaryButton,
  secondaryButton,
  tertiaryButton,
} from '@components/styles/button_appearance.jsx';

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faDownload, faExternalLink } from '@fortawesome/sharp-regular-svg-icons';

const Button = (props) => {
  const { theme } = usePageCtx();
  const [buttonType, setButtonType] = useState(props.blok.type);
  const [outboundIcon, setOutboundIcon] = useState(faArrowRightLong);
  const [url, setURL] = useState('');

  useEffect(() => {
    props.blok.outbound_icon === 'download'
      ? setOutboundIcon(faDownload)
      : setOutboundIcon(faArrowRightLong);
    props.blok.link.anchor
      ? setURL('/' + props.blok.link.cached_url + '#' + props.blok.link.anchor)
      : setURL('/' + props.blok.link.cached_url);
    props.blok.link.linktype === 'url'
      ? setURL(props.blok.link.cached_url)
      : setURL('/' + props.blok.link.cached_url);
  }, []);

  const goBack = () => {
    globalHistory.navigate(-1);
  };

  if (props.blok.back_button) {
    return (
      <a onClick={goBack} className={clsx(`btn ${props.blok.text}`)} id={'code'} role="button">
        <span>{props.blok.text}</span>
      </a>
    );
  } else {
    return (
      <>{props.blok.link.linktype === 'story' ? renderInternalButton() : renderExternalButton()}</>
    );
  }
  function renderInternalButton() {
    return props.blok.new_window ? (
      // a tag so we can open an internal link in a new window (not possible with gatsby-link)
      <a
        href={url}
        className={clsx(
          buttonType === 'primary' && primaryButton(),
          buttonType === 'secondary' &&
            secondaryButton(!props.blok.link.cached_url && { opacity: 'disabled' }),
          buttonType === 'tertiary' && tertiaryButton(),
        )}
        role="button"
        target="_blank"
        tabIndex={0}
      >
        {buttonType === 'tertiary' && (
          <div className={clsx('flex items-center pr-2', 'text-accent')}>
            <FontAwesomeIcon icon={outboundIcon} />
          </div>
        )}
        <span>{props.blok.text}</span>
        {buttonType != 'tertiary' && (
          <div className={clsx('flex items-center pl-2')}>
            <FontAwesomeIcon icon={outboundIcon} />
          </div>
        )}
      </a>
    ) : (
      <Link
        to={url}
        className={clsx(
          buttonType === 'primary' && primaryButton(),
          buttonType === 'secondary' &&
            secondaryButton(!props.blok.link.cached_url && { opacity: 'disabled' }),
          buttonType === 'tertiary' && tertiaryButton(props.darkMode && { darkMode: true }),
        )}
        role="button"
        tabIndex={0}
      >
        {buttonType === 'tertiary' && props.blok.outbound_icon != false && (
          <div className={clsx('flex items-center pr-2', 'text-accent')}>
            <FontAwesomeIcon icon={outboundIcon} />
          </div>
        )}
        <span>{props.blok.text}</span>
      </Link>
    );
  }
  function renderExternalButton() {
    return (
      <a
        href={url}
        className={clsx(
          buttonType === 'primary' && primaryButton(),
          buttonType === 'secondary' &&
            secondaryButton(!props.blok.link.cached_url && { opacity: 'disabled' }),
          buttonType === 'tertiary' && tertiaryButton(),
        )}
        role="button"
        target="_blank"
        tabIndex={0}
      >
        {buttonType === 'tertiary' && (
          <div className={clsx('flex items-center pr-2', 'text-accent')}>
            <FontAwesomeIcon icon={faExternalLink} />
          </div>
        )}
        <span>{props.blok.text}</span>
        {buttonType != 'tertiary' && (
          <div className={clsx('flex items-center pl-2')}>
            <FontAwesomeIcon icon={faExternalLink} />
          </div>
        )}
      </a>
    );
  }
};

export default Button;
