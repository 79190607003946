import React from 'react';

const ReactTetris = React.lazy(() => import('react-tetris'));

const Tetris = () => {
  const isSSR = typeof window === 'undefined';

  return <>{!isSSR && <React.Suspense fallback={<div />}>{renderTetris()}</React.Suspense>}</>;
};

function renderTetris() {
  return (
    <ReactTetris>
      {({ HeldPiece, Gameboard, PieceQueue, points, linesCleared }) => (
        <div className="inline">
          <div>
            <div className="points">
              Points:
              <br />
              {points}
            </div>
            <div className="lines">
              Lines:
              <br />
              {linesCleared}
            </div>
            Held
            <HeldPiece />
          </div>
          <div>
            Game
            <Gameboard />
          </div>
          <div>
            Queue
            <PieceQueue />
          </div>
        </div>
      )}
    </ReactTetris>
  );
}

export default Tetris;
